// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-demos-face-mask-js": () => import("./../../../src/pages/demos/face-mask.js" /* webpackChunkName: "component---src-pages-demos-face-mask-js" */),
  "component---src-pages-demos-hapi-js": () => import("./../../../src/pages/demos/hapi.js" /* webpackChunkName: "component---src-pages-demos-hapi-js" */),
  "component---src-pages-demos-js": () => import("./../../../src/pages/demos.js" /* webpackChunkName: "component---src-pages-demos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-cookies-js": () => import("./../../../src/pages/legal/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-js" */),
  "component---src-pages-legal-politica-de-privacidad-js": () => import("./../../../src/pages/legal/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

