module.exports = [{
      plugin: require('../plugins/ga-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-153075842-1","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-classes","options":{"classMap":{"h1":"title is-4 has-text-weight-bold mt-40","h2":"title is-5","paragraph":"has-text-weight-normal has-text-black mb-20"}}},{"resolve":"gatsby-remark-relative-images","options":{"name":"uploads"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1920,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
